import { Spacer } from "~/widgets/spacer";
import { ProfileCardsList } from "./profile_cards_list";
import { ProfileImage } from "./profile_image";
import { SupportIcon } from "~/assets/svg_icons/support_icon";
import { DividerV2 } from "~/widgets/divider";
import { VoucherDetails } from "~/server/types/order";
import { BrandCollectionHeader } from "../brand-collection-landing/brand_collection_header";
import { openIntercom } from "~/utils/third_party/intercom";
import { zIndexes } from "~/z_indexes";

export type BrandCollectionProfilePageProps = {
  giftingKey: string;
  coinsCount: number;
  phoneNumber: string;
  cardList: VoucherDetails[];
  themeImageUrl: string;
  logoUrl?: string;
};

export function BrandCollectionProfilePageComponent(
  props: BrandCollectionProfilePageProps
) {
  return (
    <div class=" relative bg-baseDark text-baseTertiaryLight">
      <img
        src={props.themeImageUrl}
        alt=""
        class="pointer-events-none absolute top-[40px] "
      />
      <BrandCollectionHeader
        showBalance={() => true}
        showProfile={() => true}
        isAbsoluteHeader={false}
        showHeader={() => true}
        coinsAvailable={() => props.coinsCount}
        giftingKey={props.giftingKey}
        logoUrl={props.logoUrl}
      />
      <div
        class="no-scrollbar flex w-full max-w-[450px] flex-col items-start justify-start overflow-x-hidden bg-baseDark px-4 text-baseTertiaryLight "
        style={{
          "z-index": zIndexes.profileIcon,
        }}
      >
        <Spacer height={24} />
        <UserDetails />
        <Spacer height={17} />
        <MyGiftCardDivider />
        <Spacer height={24} />
        <ProfileCardsList cardList={props.cardList} />
      </div>
    </div>
  );

  function UserDetails() {
    return (
      <>
        <div class="mt-16 flex w-full flex-row items-start justify-between ">
          <div
            class=" rounded-full border border-basePrimaryDark bg-baseDark"
            style={{
              "z-index": zIndexes.profileIcon,
            }}
          >
            <ProfileImage />
          </div>
          <div
            onClick={() => {
              openIntercom();
            }}
            style={{
              "z-index": zIndexes.profileIcon,
            }}
            class=" flex flex-row justify-center gap-1 rounded-full border border-basePrimaryDark bg-[#020202] px-2 py-[7px]"
          >
            <SupportIcon />
            <span class="text-medium text-textNormal">Support</span>
          </div>
        </div>
        <Spacer height={12} />
        <span class="text-h3 text-baseTertiaryLight">{props.phoneNumber}</span>
      </>
    );
  }

  function MyGiftCardDivider() {
    return (
      <div class="flex w-full flex-row items-center justify-center gap-2">
        <span class="grow text-nowrap text-medium text-baseTertiaryLight">
          My gift cards
        </span>
        <DividerV2 class=" bg-basePrimaryDark" />
      </div>
    );
  }
}
