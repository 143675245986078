import { BrandCollectionProfilePageComponent } from "~/components/gifting/brand-collection-profile/brand_collection_profile";
import { getGiftBoxV3ProfileRouteData } from "~/server/data/gifting_v3/gift_box_v3_profile_route_data";
import { cache, createAsync, useIsRouting, useParams } from "@solidjs/router";
import { Show, Suspense } from "solid-js";
import ClientOnlyComponent, {
  ClientComponent,
} from "~/client_only_components/client_component";
import { DottedLoader } from "~/widgets/loader";

export default function BrandCollectionProfileRoute() {
  const ProfileRouteData$C = cache(
    getGiftBoxV3ProfileRouteData,
    "GiftBoxV3ProfileRoute"
  );

  const gitingV3defaultBg =
    "https://firebasestorage.googleapis.com/v0/b/flutter-festival-a9d56.appspot.com/o/multi-brand-jewellery-bg.png?alt=media&token=5ceced0e-09cf-4de1-a2f4-5ec70170ce0c";

  const params = useParams();
  const isRouting = useIsRouting();
  const giftingKey = params.giftingKey as string;

  const routeData = createAsync(
    async () => {
      return await ProfileRouteData$C({
        giftingKey,
      });
    },
    { deferStream: true }
  );

  return (
    <>
      <ClientOnlyComponent component={ClientComponent.SnackbarHost} />

      <Show
        when={routeData() && !isRouting()}
        fallback={<DottedLoader color="#999" />}
      >
        <BrandCollectionProfilePageComponent
          cardList={routeData()!.cardsList}
          coinsCount={routeData()!.coinsCount}
          giftingKey={giftingKey}
          phoneNumber={routeData()!.phoneNumber ?? ""}
          themeImageUrl={gitingV3defaultBg}
          logoUrl={routeData()!.logoUrl}
        />
      </Show>
    </>
  );
}
